import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, CONTACT } from '../../constants/defaultValues';

const startFetchSearchFields = createAsyncThunk('contact/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('contact/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFieldsContactActions = createAsyncThunk('contact/startFetchDataFieldsContactActions', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/dataFieldsContactActions?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchActivityLog = createAsyncThunk('contact/startFetchActivityLog', async ({ contact_id, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/activityLog?lang=${locale}&contact_id=${contact_id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchAccounts = createAsyncThunk('contact/startFetchAccounts', async ({ contact_id, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/accounts?lang=${locale}&contact_id=${contact_id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateContactAction = createAsyncThunk('contact/startCreateContactAction', async ({ action, locale, token, form }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CONTACT}/storeAction`, action, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        dispatch(startFetchContactActions({ contact_id: action.get('contact_id'), locale: locale, token: token }))

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteContactAction = createAsyncThunk('contact/startDeleteContactAction', async ({ locale, token, action_id, contact_id, toggleModal }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/destroyAction/${action_id}`, { headers });

        toggleModal();

        dispatch(startFetchContactActions({ contact_id: contact_id, locale: locale, token: token }))

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startSavePropertiesAction = createAsyncThunk('contact/startSavePropertiesAction', async ({ locale, token, properties, id, actions }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        await axios.post(`${CONTACT}/savePropertiesAction/${id}`, {properties: JSON.stringify(properties)}, { headers });

        let new_actions = JSON.parse(JSON.stringify([...actions]));

        new_actions.forEach((action, i) => {
            if(action.id === id){
                new_actions[i].properties = properties;
            }
        })

        return new_actions;
    } catch (error) {
        console.log(error)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateContactAction = createAsyncThunk('contact/startUpdateContactAction', async ({ id, action, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CONTACT}/updateAction/${id}`, action, { headers });

        if(res.data){
            form.setFieldValue('files', []);
            form.setStatus({ success: true });
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false });
        form.setSubmitting(false);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchContactActions = createAsyncThunk('contact/startFetchContactActions', async ({ contact_id, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/contactActions?lang=${locale}&contact_id=${contact_id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchContactEmails = createAsyncThunk('contact/startFetchContactEmails', async ({ contact_id, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/contactEmails?lang=${locale}&contact_id=${contact_id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchContactEmail = createAsyncThunk('contact/startFetchContactEmail', async ({ contact_id, email_id, folder, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/contactEmail?lang=${locale}&contact_id=${contact_id}&email_id=${email_id}&folder=${folder}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDownloadFileEmail = createAsyncThunk('contact/startDownloadFileEmail', async ({ contact_id, email_id, folder, filename, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/downloadFileEmail?lang=${locale}&contact_id=${contact_id}&email_id=${email_id}&folder=${folder}&filename=${filename}`, { responseType: "arraybuffer", headers });

        const url = window.URL.createObjectURL(new Blob([res.data]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
        'download',
        filename,
        );
      
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url)

        return true;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveAccount = createAsyncThunk('contact/startActiveAccount', async ({ locale, token, id, portal, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${CONTACT}/activeAccount/${portal}/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startChangeAccount = createAsyncThunk('contact/startChangeAccount', async ({ locale, token, id, onsea_id, portal, action, accounts_data, index_account }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${CONTACT}/changeAccount/${portal}/${id}/${onsea_id}`, { action: action }, { headers });

        let new_account = [...accounts_data];

        new_account[index_account] = res.data.data;

        dispatch(contactSlice.actions.accounts(new_account));

        return res.data.status;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startChangeManualAccount = createAsyncThunk('contact/startChangeManualAccount', async ({ locale, token, id, onsea_id, portal, accounts_data, index_account, action }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${CONTACT}/changeManualAccount/${portal}/${id}/${onsea_id}`, { action: action }, { headers });

        let new_account = [...accounts_data];

        new_account[index_account] = res.data.data;

        dispatch(contactSlice.actions.accounts(new_account));

        return res.data.status;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchIp = createAsyncThunk('contact/startFetchIp', async ({ ip, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CONTACT}/fetchIpInfo`, ip, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPropContact = createAsyncThunk('contact/startFetchPropContact', async ({ id, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/fetchPropContact/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchReqContact = createAsyncThunk('contact/startFetchReqContact', async ({ id, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/fetchReqContact/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchRecommendationContact = createAsyncThunk('contact/startFetchRecommendationContact', async ({ id, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/fetchRecommendationContact/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


const contactStats = createAsyncThunk('contact/contactStats', async ({ contact_id, search_id, portal_id, from_date, to_date, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/stats?contact_id=${contact_id}&search_id=${search_id}&portal_id=${portal_id}&from=${from_date}&to=${to_date}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startContactInfo = createAsyncThunk('contact/startContactInfo', async ({ contact, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/edit/${contact}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveContact = createAsyncThunk('contact/startActiveContact', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${CONTACT}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteContact = createAsyncThunk('contact/startDeleteContact', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${CONTACT}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateContact = createAsyncThunk('contact/startCreateContact', async ({ contact, locale, token, form, user_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CONTACT}/store`, contact, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
            form.setFieldValue('operator', user_id)
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateContact = createAsyncThunk('contact/startUpdateContact', async ({ id, contact, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CONTACT}/update/${id}`, contact, { headers });

        if(res.data){
            form.setFieldValue('files', []);
            form.setFieldValue('filesData', res.data.filesData);
            form.setStatus({ success: true });
        }

        return res.data.status;
    } catch (error) {
        form.setStatus({ success: false });
        form.setSubmitting(false);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchContact = createAsyncThunk('contact/startFetchContact', async ({ contact, history, locale, token }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/edit/${contact}`, { headers });
        const data = res.data;

        const results = {
          edit: true,
          id: data.id,
          name: data.name,
          surname: data.surname,
          fathers_name: data.fathers_name,
          gender: data.gender,
          email: data.email,
          contact_type: data.contact_type,
          priority_pool: data.priority_pool,
          spoken_language: data.spoken_language,
          country: data.country,
          nationality: data.nationality,
          iso: data.iso,
          timezone: data.timezone,
          preferred_communication_id: data.preferred_communication_id,
          contact_source: data.contact_source,
          rec_contact: data.rec_contact ? data.rec_contact : "",
          rec_contacts: data.rec_contacts,
          rec_loading: false,
          extraPhones: data.extraPhones,
          extraMobiles: data.extraMobiles,
          socials: data.socials,
          address: data.address ? data.address : "",
          lat: data.lat ? Number(data.lat) : "",
          lng: data.lng ? Number(data.lng) : "",
          birthday: data.birthday ? new Date(data.birthday) : null,
          age_range_id:  data.age_range_id ? data.age_range_id : '',
          cooperative_owner_id: data.cooperative_owner_id ? data.cooperative_owner_id : "",
          vat: data.vat ? data.vat : "",
          tax: data.tax ? data.tax : "",
          passport: data.passport ? data.passport : "",
          company: data.company ? data.company : "",
          occupation: data.occupation ? data.occupation : "",
          operator: data.operator ? data.operator : "",
          portal: data.portal,
          newsLetter: data.newsLetter,
          offers_duration: data.offers_duration,
          notes: data.notes ? data.notes : '',
          score: data.score,
          files: [],
          filesData: data.filesData
        };

        let files = []

        data.filesData.forEach(element => {
            files.push(element.type)
        });

        if(data.address && data.birthday && data.fathers_name && data.nationality && data.occupation 
            && data.passport && data.vat && files.includes(24) && files.includes(26) && files.includes(27)){
            dispatch(contactSlice.actions.setHasFields(true))
        }else{
            dispatch(contactSlice.actions.setHasFields(false))
        }

        if(data.dueDiligence > 0){
            dispatch(contactSlice.actions.setDueValid(true))
        }else{
            dispatch(contactSlice.actions.setDueValid(false))
        }
        
        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchContacts = createAsyncThunk('contact/startFetchContacts', async ({ locale, token, filter, loading }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(contactSlice.actions.actionFetchContacts(loading))
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}?
        pageSize=${filter.pageSize}
        &currentPage=${filter.currentPage}
        &orderBy=${filter.orderBy}
        &search=${filter.search}
        &contact_type=${filter.searchFilter.contact_type.length > 0 ? filter.searchFilter.contact_type.join() : ""}
        &gender=${filter.searchFilter.gender.length > 0 ? filter.searchFilter.gender.join() : ""}
        &source=${filter.searchFilter.source.length > 0 ? filter.searchFilter.source.join() : ""}
        &operator=${filter.searchFilter.operator.length > 0 ? filter.searchFilter.operator.join() : ""}
        &preferredCommunication=${filter.searchFilter.preferredCommunication.length > 0 ? filter.searchFilter.preferredCommunication.join() : ""}
        &country=${filter.searchFilter.country.length > 0 ? filter.searchFilter.country.join() : ""}
        &nationality=${filter.searchFilter.nationality.length > 0 ? filter.searchFilter.nationality.join() : ""}
        &spoken_language=${filter.searchFilter.spoken_language.length > 0 ? filter.searchFilter.spoken_language.join() : ""}
        &status=${filter.searchFilter.status.length > 0 ? filter.searchFilter.status.join() : ""}
        &portal=${filter.searchFilter.portal.length > 0 ? filter.searchFilter.portal.join() : ""}
        &occupation=${filter.searchFilter.occupation.length > 0 ? filter.searchFilter.occupation.join() : ""}
        &timezone=${filter.searchFilter.timezone.length > 0 ? filter.searchFilter.timezone.join() : ""}
        &range=${filter.searchFilter.range.length > 0 ? filter.searchFilter.range.join() : ""}
        &cooperative=${filter.searchFilter.cooperative.length > 0 ? filter.searchFilter.cooperative.join() : ""}
        &priorityPool=${filter.searchFilter.priorityPool.length > 0 ? filter.searchFilter.priorityPool.join() : ""}
        &facebook=${filter.searchFilter.facebook.length > 0 ? filter.searchFilter.facebook.join() : ""}
        &instagram=${filter.searchFilter.instagram.length > 0 ? filter.searchFilter.instagram.join() : ""}
        &linkedIn=${filter.searchFilter.linkedIn.length > 0 ? filter.searchFilter.linkedIn.join() : ""}
        &register=${filter.searchFilter.register.length > 0 ? filter.searchFilter.register.join() : ""}
        &member=${filter.searchFilter.member.length > 0 ? filter.searchFilter.member.join() : ""}
    
        &fromDate_type=${filter.searchFilter.fromDate_type}
        &toDate_type=${filter.searchFilter.toDate_type}
        &fromDateReg_type=${filter.searchFilter.fromDateReg_type}
        &toDateReg_type=${filter.searchFilter.toDateReg_type}
        `, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchContactDiligence = createAsyncThunk('contact/startFetchContactDiligence', async ({ contact_id, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/fetchDiligence/${contact_id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startSaveContactDiligence = createAsyncThunk('contact/startSaveContactDiligence', async ({ contact_id, diligence, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CONTACT}/saveDiligence/${contact_id}`, diligence, { headers });

        if(res.data){
            form.setStatus({ success: true });
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false });
        form.setSubmitting(false);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


//Heading Functions
const setSelectedOrderOption = createAsyncThunk('contact/setSelectedOrderOption', ( args ) => {
    return args;
});

const setSearch = createAsyncThunk('contact/setSearch', ( args ) => {
    return args;
});

const setCurrentPage = createAsyncThunk('contact/setCurrentPage', ( args ) => {
    return args;
});

const setSort = createAsyncThunk('contact/setSort', ( args ) => {
    return args;
});

const initialState = {
    contacts: {
        data: [],
        totalItemCount: 0
    },
    displayMode: "list",
    selectedPageSize: 10,
    selectedOrderOption: "",
    search: "",
    searchValue: "",
    searchFilterType: 1,
    fields: [],
    searchFilter: [
        { translate: "general.contact-type", id: "contact_type", dataKey: "types", search: "", type: 1 },
        { translate: "general.gender", id: "gender", dataKey: "genders", search: "", type: 1 },
        { translate: "general.source", id: "source", dataKey: "sources", search: "", type: 1 },
        { translate: "general.contact-operator", id: "operator", dataKey: "operators", search: "", type: 1 },
        { translate: "general.preferred-communication", id: "preferredCommunication", dataKey: "preferredCommunications", search: "", type: 1 },
        { translate: "general.countries", id: "country", dataKey: "countries", search: "", type: 1 },
        { translate: "general.nationalities", id: "nationality", dataKey: "countries", search: "", type: 1 },
        { translate: "general.spoken-language", id: "spoken_language", dataKey: "languages", search: "", type: 1 },
        { translate: "general.status", id: "status", dataKey: "status", search: "", type: 1 },
        { translate: "general.portals-accounts", id: "portal", dataKey: "portals", search: "", type: 1 },
        { translate: "general.occupation", id: "occupation", dataKey: "occupations", search: "", type: 1 },
        { translate: "general.timezone", id: "timezone", dataKey: "timezones", search: "", type: 1 },
        { translate: "general.age-range", id: "range", dataKey: "ranges", search: "", type: 1 },
        { translate: "general.cooperativeness", id: "cooperative", dataKey: "cooperatives", search: "", type: 1 },
        { translate: "general.priority-pool", id: "priorityPool", dataKey: "priorityPool", search: "", type: 1 },
        { translate: "general.fb", id: "facebook", dataKey: "facebook", search: "", type: 1 },
        { translate: "general.instagram", id: "instagram", dataKey: "instagram", search: "", type: 1 },
        { translate: "general.linkedin", id: "linkedIn", dataKey: "linkedIn", search: "", type: 1 },
        ////smart filtering
        // { translate: "last-visit-website", id: "lastVisit", dataKey: "lastVisits", type: 2 },
        // { translate: "last-agent-activity", id: "lastActivity", dataKey: "lastActivities", type: 2 },
        // { translate: "any-conversions", id: "conversion", dataKey: "conversions", type: 2 }

        { translate: "general.last-action", id: "lastAction_type", dataKey: "", search: "", type: 2 },
        { translate: "general.register", id: "register", dataKey: "register", search: "", type: 2 },
        { translate: "general.site-member", id: "member", dataKey: "members", search: "", type: 2 },
        { translate: "general.reg-date", id: "regDate", dataKey: "", search: "", type: 2 },
    ],
    searchFilterValues: {
        contact_type: [],
        gender: [],
        source: [],
        operator: [],
        country: [],
        nationality: [],
        preferredCommunication: [],
        spoken_language: [],
        status: [],
        portal: [],
        occupation: [],
        timezone: [],
        range: [],
        cooperative: [],
        member: [],
        priorityPool: [],
        facebook: [],
        instagram: [],
        linkedIn: [],
        //smart filtering
        // lastVisit: [],
        // lastActivity: [],
        // conversion: [],
        register: [],

        fromDate_type: "",
        toDate_type: "",
        fromDateReg_type: "",
        toDateReg_type: "",
    },
    currentPage: 1,
    contactData: {
        bucket_id: '',
        type: '',
        name: '',
        surname: '',
        fathers_name: '',
        gender: '',
        email: '',
        contact_type: [],
        priority_pool: 0,
        spoken_language: [],
        country: '',
        nationality: '',
        iso: '',
        timezone: '',
        preferred_communication_id: [],
        contact_source: '',
        rec_contact: '',
        rec_contacts: [],
        rec_loading: false,
        extraPhones: [],
        extraMobiles: [],
        socials: [],
        address: '',
        lat: '',
        lng: '',
        birthday: null,
        vat: '',
        tax: '',
        passport: '',
        age_range_id: '',
        cooperative_owner_id: '',
        company: '',
        occupation: '',
        operator: '',
        portal: '',
        newsLetter: 1,
        offers_duration: 3,
        notes: '',
        score: 0,
        files: [],
        filesData: []
    },
    createContact: '',
    updateContact: '',
    deleteContact: '',
    activeContact: '',
    activeAccount: '',
    changeAccount: '',
    changeManualAccount: '',
    contactInfo: '',
    propContact: [],
    reqContact: [],
    recommendationContact: [],
    activityLogData: [],
    accountsData: [],
    contactActions: [],
    contactEmails: [],
    createContactAction: '',
    deleteContactAction: '',
    updateContactAction: '',
    saveDiligence: '',
    ipInfo: [],
    contactActionData: {
        edit: false,
        action_id: '',
        place_id: '',
        price: '',
        appointment_date: new Date(),
        minus_plus: 1,
        description: '',
        properties: [{ property_id: '', minus_plus: 1 }],
        files: [],
        filesData: []
    },
    contactEmailData: {
        message: '',
        files: []
    },
    contactDiligence: {
        diligences: []
    },
    dataFields: {
        genders: [],
        types: [],
        languages: [],
        sources: [],
        preferredCommunications: [],
        occupations: [],
        operators: [],
        files: [],
        countries: [],
        timezones: [],
        portals: [],
        ranges: [],
        cooperatives: [],
        options: [],
        durations: []
    },
    dataFieldsContactActions: {
        actions: [],
        places: [],
        files: []
    },
    contactStatsData: {
        portal_id: "",
        banners: {
            last_visit: "",
            last_action: "",
            saved_props: 0,
            alerts: 0
        },
        properties_subs: {
            labels: [],
            data: [],
            border: [],
            background: []
        },
        properties_areas: {
            labels: [],
            data: [],
            border: [],
            background: []
        },
        web_visits: {
            labels: [],
            data: []
        },
        price_range: {
            min: 0,
            max: 0,
            avg: 0
        },
        properties_list: []
    },
    hasFields: false,
    isValidDue: false,
    loadingContactDiligenceBtn: false, 
    loadingContactDiligence: false,
    loadingActivityLog: false,
    loadingAccounts: false,
    loadingContactActions: false,
    loadingContactActionsBtn: false,
    loadingBtn: false,
    loading: true,
    loadingModal: false,
    loadingPropModal: true,
    loadingReqModal: true,
    loadingRecommendationModal: true,
    loadingMap: false,
    loadingSearch: false,
    loadingContactEmails: false,
    loadingContactEmailModal: false,
    error: ''
}

export const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        fetchContactsSuccess(state, { payload }) {
            return { ...state, loading: false, contacts: payload, error: '' };
        },
        actionFetchContacts(state, { payload }) {
            return { ...state, loading: payload ? true : false, loadingSearch: true, error: '' };
        },
        accounts(state, { payload }) {
            return { ...state, accountsData: payload, loadingAccounts: false, error: '' };
        },
        clearContact(state) {
            return { ...state, loadingBtn: false, loading: false, loadingSearch: false, contactData: initialState.contactData, hasFields: false, isValidDue: false, error: '' };
        },
        clearAlertContact(state) {
            return { ...state, updateContact: '', createContact: '', deleteContact: '', activeContact: '', activeAccount: '', 
            changeAccount: '', changeManualAccount: '', createContactAction: '', updateContactAction: '', saveDiligence: '', error: '' };
        },
        clearContactStats(state) {
            return { ...state, loadingModal: false, contactStatsData: initialState.contactStatsData, error: ''};
        },

        setDisplayMode(state, { payload }) {
            return { ...state, displayMode: payload };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 };
        },
        setFilter(state, { payload }) {
            return { ...state, searchFilterValues: payload };
        },
        setFilterType(state, { payload }) {
            return { ...state, searchFilterType: payload };
        },
        setFilterFieldSearch(state, { payload }) {
            return { ...state, fields: payload.dataValues, searchFilter: payload.values };
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        setHasFields(state, { payload }) {
            return { ...state, hasFields: payload }
        },
        setDueValid(state, { payload }) {
            return { ...state, isValidDue: payload }
        }
    },
    extraReducers: {
      [startFetchSearchFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        return { ...state, fields: payload, error: '' };
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, fields: initialState.fields, error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchDataFieldsContactActions.pending]: (state) => {
        return { ...state, loadingContactActions: true, error: '' };
      },
      [startFetchDataFieldsContactActions.fulfilled]: (state, { payload }) => {
        return { ...state, dataFieldsContactActions: payload, error: '' };
      },
      [startFetchDataFieldsContactActions.rejected]: (state, { payload }) => {
        return { ...state, loadingContactActions: false, dataFieldsContactActions: initialState.dataFieldsContactActions, error: payload };
      },

      [startFetchActivityLog.pending]: (state) => {
        return { ...state, loadingActivityLog: true, error: '' };
      },
      [startFetchActivityLog.fulfilled]: (state, { payload }) => {
        return { ...state, activityLogData: payload, loadingActivityLog: false, error: '' };
      },
      [startFetchActivityLog.rejected]: (state, { payload }) => {
        return { ...state, loadingActivityLog: false, activityLogData: initialState.activityLogData, error: payload };
      },

      [startFetchAccounts.pending]: (state) => {
        return { ...state, loadingAccounts: true, error: '' };
      },
      [startFetchAccounts.fulfilled]: (state, { payload }) => {
        return { ...state, accountsData: payload, loadingAccounts: false, error: '' };
      },
      [startFetchAccounts.rejected]: (state, { payload }) => {
        return { ...state, loadingAccounts: false, accountsData: initialState.accountsData, error: payload };
      },

      [startCreateContactAction.pending]: (state) => {
        return { ...state, loadingContactActionsBtn: true, error: '' };
      },
      [startCreateContactAction.fulfilled]: (state, { payload }) => {
        return { ...state, loadingContactActionsBtn: false, createContactAction: payload, error: '' };
      },
      [startCreateContactAction.rejected]: (state, { payload }) => {
        return { ...state, loadingContactActionsBtn: false, createContactAction: '', error: payload };
      },

      [startDeleteContactAction.pending]: (state) => {
        return { ...state, loadingContactActionsBtn: true, error: '' };
      },
      [startDeleteContactAction.fulfilled]: (state, { payload }) => {
        return { ...state, loadingContactActionsBtn: false, deleteContactAction: payload, error: '' };
      },
      [startDeleteContactAction.rejected]: (state, { payload }) => {
        return { ...state, loadingContactActionsBtn: false, deleteContactAction: '', error: payload };
      },

      [startUpdateContactAction.pending]: (state) => {
        return { ...state, loadingContactActionsBtn: true, error: '' };
      },
      [startUpdateContactAction.fulfilled]: (state, { payload }) => {
        return { ...state, loadingContactActionsBtn: false, updateContactAction: payload, error: '' };
      },
      [startUpdateContactAction.rejected]: (state, { payload }) => {
        return { ...state, loadingContactActionsBtn: false, updateContactAction: '', error: payload };
      },

      [startFetchContactActions.pending]: (state) => {
        return { ...state, loadingContactActions: true, error: '' };
      },
      [startFetchContactActions.fulfilled]: (state, { payload }) => {
        return { ...state, contactActions: payload, loadingContactActions: false, error: '' };
      },
      [startFetchContactActions.rejected]: (state, { payload }) => {
        return { ...state, loadingContactActions: false, contactActions: initialState.contactActions, error: payload };
      },

      [startFetchContactEmails.pending]: (state) => {
        return { ...state, loadingContactEmails: true, error: '' };
      },
      [startFetchContactEmails.fulfilled]: (state, { payload }) => {
        return { ...state, contactEmails: payload, loadingContactEmails: false, error: '' };
      },
      [startFetchContactEmails.rejected]: (state, { payload }) => {
        return { ...state, loadingContactEmails: false, contactEmails: initialState.contactEmails, error: payload };
      },

      [startFetchContactEmail.pending]: (state) => {
        return { ...state, loadingContactEmailModal: true, error: '' };
      },
      [startFetchContactEmail.fulfilled]: (state, { payload }) => {
        return { ...state, contactEmailData: payload, loadingContactEmailModal: false, error: '' };
      },
      [startFetchContactEmail.rejected]: (state, { payload }) => {
        return { ...state, loadingContactEmailModal: false, contactEmailData: initialState.contactEmailData, error: payload };
      },

      [startDownloadFileEmail.fulfilled]: (state) => {
        return { ...state, error: '' };
      },
      [startDownloadFileEmail.rejected]: (state, { payload }) => {
        return { ...state, error: payload };
      },

      [startActiveAccount.fulfilled]: (state, { payload }) => {
        return { ...state, loadingAccounts: false, activeAccount: payload, error: '' };
      },
      [startActiveAccount.rejected]: (state, { payload }) => {
        return { ...state, loadingAccounts: false, activeAccount: '', error: payload };
      },

      [startChangeAccount.fulfilled]: (state, { payload }) => {
        return { ...state, loadingAccounts: false, changeAccount: payload, error: '' };
      },
      [startChangeAccount.rejected]: (state, { payload }) => {
        return { ...state, loadingAccounts: false, changeAccount: '', error: payload };
      },

      [startChangeManualAccount.fulfilled]: (state, { payload }) => {
        return { ...state, loadingAccounts: false, changeManualAccount: payload, error: '' };
      },
      [startChangeManualAccount.rejected]: (state, { payload }) => {
        return { ...state, loadingAccounts: false, changeManualAccount: '', error: payload };
      },

      [startFetchIp.pending]: (state) => {
        return { ...state, loadingMap: true, error: '' };
      },
      [startFetchIp.fulfilled]: (state, { payload }) => {
        return { ...state, loadingMap: false, ipInfo: payload, error: '' };
      },
      [startFetchIp.rejected]: (state, { payload }) => {
        return { ...state, loadingMap: false, ipInfo: '', error: payload };
      },

      [startFetchPropContact.pending]: (state) => {
        return { ...state, loadingPropModal: true, error: '' };
      },
      [startFetchPropContact.fulfilled]: (state, { payload }) => {
        return { ...state, loadingPropModal: false, propContact: payload, error: '' };
      },
      [startFetchPropContact.rejected]: (state, { payload }) => {
        return { ...state, loadingPropModal: false, propContact: [], error: payload };
      },

      [startFetchReqContact.pending]: (state) => {
        return { ...state, loadingReqModal: true, error: '' };
      },
      [startFetchReqContact.fulfilled]: (state, { payload }) => {
        return { ...state, loadingReqModal: false, reqContact: payload, error: '' };
      },
      [startFetchReqContact.rejected]: (state, { payload }) => {
        return { ...state, loadingReqModal: false, reqContact: [], error: payload };
      },

      [startFetchRecommendationContact.pending]: (state) => {
        return { ...state, loadingRecommendationModal: true, error: '' };
      },
      [startFetchRecommendationContact.fulfilled]: (state, { payload }) => {
        return { ...state, loadingRecommendationModal: false, recommendationContact: payload, error: '' };
      },
      [startFetchRecommendationContact.rejected]: (state, { payload }) => {
        return { ...state, loadingRecommendationModal: false, recommendationContact: [], error: payload };
      },

      [contactStats.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [contactStats.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, contactStatsData: payload, error: '' };
      },
      [contactStats.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, contactStatsData: initialState.contactStatsData, error: payload };
      },

      [startContactInfo.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startContactInfo.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, contactInfo: payload, error: '' };
      },
      [startContactInfo.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, contactInfo: '', error: payload };
      },

      [startActiveContact.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeContact: payload, error: '' };
      },
      [startActiveContact.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeContact: '', error: payload };
      },

      [startDeleteContact.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteContact: payload, error: '' };
      },
      [startDeleteContact.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteContact: '', error: payload };
      },

      [startCreateContact.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateContact.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createContact: payload, error: '' };
      },
      [startCreateContact.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createContact: '', error: payload };
      },

      [startUpdateContact.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateContact.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateContact: payload, error: '' };
      },
      [startUpdateContact.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateContact: '', error: payload };
      },

      [startFetchContact.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchContact.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, contactData: payload, error: '' };
      },
      [startFetchContact.rejected]: (state, { payload }) => {
        return { ...state, loading: false, contactData: initialState.contactData, error: payload };
      },

      [startFetchContacts.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, contacts: payload, error: '' };
      },
      [startFetchContacts.rejected]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, contacts: initialState.contacts, error: payload };
      },

      [startSavePropertiesAction.fulfilled]: (state, { payload }) => {
        return { ...state, contactActions: payload, error: '' };
      },
      [startSavePropertiesAction.rejected]: (state, { payload }) => {
        return { ...state, contactActions: initialState.contactActions, error: payload };
      },

      [startFetchContactDiligence.pending]: (state) => {
        return { ...state, loadingContactDiligence: true, error: '' };
      },
      [startFetchContactDiligence.fulfilled]: (state, { payload }) => {
        return { ...state, contactDiligence: payload, loadingContactDiligence: false, error: '' };
      },
      [startFetchContactDiligence.rejected]: (state, { payload }) => {
        return { ...state, loadingContactDiligence: false, contactDiligence: initialState.contactDiligence, error: payload };
      },

      [startSaveContactDiligence.pending]: (state) => {
        return { ...state, loadingContactDiligenceBtn: true, error: '' };
      },
      [startSaveContactDiligence.fulfilled]: (state, { payload }) => {
        return { ...state, loadingContactDiligenceBtn: false, saveDiligence: payload, error: '' };
      },
      [startSaveContactDiligence.rejected]: (state, { payload }) => {
        return { ...state, loadingContactDiligenceBtn: false, saveDiligence: '', error: payload };
      },

      //Heading Functions
      [setSelectedOrderOption.fulfilled]: (state, { payload }) => {
        return { ...state, selectedOrderOption: payload, currentPage: 1 };
      },
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const contactActions = {
    ...contactSlice.actions,
    startFetchSearchFields,
    startFetchDataFields,
    startFetchDataFieldsContactActions,
    startFetchActivityLog,
    startFetchAccounts,
    startCreateContactAction,
    startDeleteContactAction,
    startUpdateContactAction,
    startFetchContactActions,
    startSavePropertiesAction,
    startFetchContactEmails,
    startFetchContactEmail,
    startDownloadFileEmail,
    startActiveAccount,
    startChangeAccount,
    startChangeManualAccount,
    startFetchIp,
    startFetchPropContact,
    startFetchReqContact,
    startFetchRecommendationContact,
    contactStats,
    startContactInfo,
    startDeleteContact,
    startActiveContact,
    startCreateContact,
    startUpdateContact,
    startFetchContact,
    startFetchContacts,
    startFetchContactDiligence,
    startSaveContactDiligence,
    setSelectedOrderOption,
    setSearch,
    setCurrentPage,
    setSort
}
export default contactSlice.reducer