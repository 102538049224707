import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, ROLES } from '../../constants/defaultValues';

const startFetchRoles = createAsyncThunk('role/startFetchRoles', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${ROLES}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateRole = createAsyncThunk('role/startCreateRole', async ({ role, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${ROLES}/store`, role, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateRole = createAsyncThunk('role/startUpdateRole', async ({ id, role, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${ROLES}/update/${id}`, role, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteRole = createAsyncThunk('role/startDeleteRole', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${ROLES}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPermissionsTypes = createAsyncThunk('role/startFetchPermissionsTypes', async ( token, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ROLES}/permissionsTypes`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSourceTypes = createAsyncThunk('role/startFetchSourceTypes', async ( token, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ROLES}/sourceTypes`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchRole = createAsyncThunk('role/startFetchRole', async ({ role, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ROLES}/edit/${role}`, { headers });

        const data = res.data;

        const results = {
          edit: true,
          role: data.name,
          permissions: data.permissions,
          calendar_list: data.calendar_list,
          contacts_list: data.contacts_list,
          absence_list: data.absence_list,
          properties_list: data.properties_list,
          assignments_list: data.assignments_list,
          requests_list: data.requests_list,
          requests_bucket_list: data.requests_bucket_list,
          indications_list: data.indications_list,
          source_type: data.source_type ? data.source_type : '',
          sh_properties: data.sh_properties,
          sh_requests: data.sh_requests,
          sh_prop_max_budget: data.sh_prop_max_budget ? data.sh_prop_max_budget : '',
          sh_prop_min_budget: data.sh_prop_min_budget ? data.sh_prop_min_budget : '',
          sh_req_max_budget: data.sh_req_max_budget ? data.sh_req_max_budget : '',
          sh_req_min_budget: data.sh_req_min_budget ? data.sh_req_min_budget : ''
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});



//Heading Functions
const setSearch = createAsyncThunk('role/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('role/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('role/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    roles: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    permissionsTypes: [],
    sourceTypes: [],
    roleData: {
        role: '',
        permissions: [],
        calendar_list: 0,
        absence_list: 0,
        contacts_list: 0,
        properties_list: 0,
        assignments_list: 0,
        requests_list: 0,
        requests_bucket_list: 0,
        indications_list: 0,
        source_type: '',
        sh_properties: 0,
        sh_requests: 0,
        sh_prop_max_budget: '',
        sh_prop_min_budget: '',
        sh_req_max_budget: '',
        sh_req_min_budget: ''
    },
    createRole: '',
    updateRole: '',
    deleteRole: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const roleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        fetchRolesSuccess(state, { payload }) {
            return { ...state, loading: false, roles: payload, error: '' };
        },
        clearRole(state) {
            return { ...state, loadingBtn: false, loading: false, roleData: initialState.roleData, error: '' };
        },
        clearAlertRole(state) {
            return { ...state, updateRole: '', createRole: '', deleteRole: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchRoles.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchRoles.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, roles: payload, error: '' };
      },
      [startFetchRoles.rejected]: (state, { payload }) => {
        return { ...state, loading: false, roles: initialState.roles, error: payload };
      },

      [startCreateRole.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateRole.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createRole: payload, error: '' };
      },
      [startCreateRole.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createRole: '', error: payload };
      },

      [startUpdateRole.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateRole.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateRole: payload, error: '' };
      },
      [startUpdateRole.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateRole: '', error: payload };
      },

      [startFetchRole.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchRole.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, roleData: payload, error: '' };
      },
      [startFetchRole.rejected]: (state, { payload }) => {
        return { ...state, loading: false, roleData: '', error: payload };
      },

      [startDeleteRole.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteRole: payload, error: '' };
      },
      [startDeleteRole.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteRole: '', error: payload };
      },

      [startFetchPermissionsTypes.fulfilled]: (state, { payload }) => {
        return { ...state, permissionsTypes: payload, error: '' };
      },
      [startFetchPermissionsTypes.rejected]: (state, { payload }) => {
        return { ...state, permissionsTypes: '', error: payload };
      },

      [startFetchSourceTypes.fulfilled]: (state, { payload }) => {
        return { ...state, sourceTypes: payload, error: '' };
      },
      [startFetchSourceTypes.rejected]: (state, { payload }) => {
        return { ...state, sourceTypes: '', error: payload };
      },

      

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const roleActions = {
    ...roleSlice.actions,
    startFetchRoles,
    startCreateRole,
    startUpdateRole,
    startFetchRole,
    startDeleteRole,
    startFetchPermissionsTypes,
    startFetchSourceTypes,
    setSearch,
    setCurrentPage,
    setSort
}
export default roleSlice.reducer