import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, PROPERTY_BUCKET } from '../../constants/defaultValues';

const startFetchSearchFields = createAsyncThunk('propertyBucketProperties/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_BUCKET}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreatePropertyBucket = createAsyncThunk('propertyBucketProperties/startCreatePropertyBucket', async ({ token, locale, name, list }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_BUCKET}/store`, { first_name: name }, { headers });

        let new_list = JSON.parse(JSON.stringify(list));

        new_list.data.unshift(res.data.data)
        new_list.totalItemCount = new_list.totalItemCount + 1;

        dispatch(propertyBucketPropertiesSlice.actions.fetchPropertyBucketsSuccess(new_list))

        return res.data.status;
    } catch (error) {
        console.log(error)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdatePropertyBucket = createAsyncThunk('propertyBucketProperties/startUpdatePropertyBucket', async ({ id, fieldKey, fieldValue, progress, locale, token, items, index }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_BUCKET}/update/${id}`, { fieldKey: fieldKey, fieldValue: fieldValue, progress: progress }, { headers });

        let new_list = JSON.parse(JSON.stringify(items));
        new_list.data[index] = res.data.item;
        dispatch(propertyBucketPropertiesSlice.actions.fetchPropertyBucketsSuccess(new_list))

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPropertyBuckets = createAsyncThunk('propertyBucketProperties/startFetchPropertyBuckets', async ({ locale, token, filter, loading, items, clear }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(propertyBucketPropertiesSlice.actions.actionFetchPropertyBuckets(loading));
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_BUCKET}?
        &tab=${filter.tab}
        &orderBy=${filter.orderBy}
        &search=${filter.search}
        &page=${filter.page}
        &gender_type=${filter.searchFilter.gender_type.length > 0 ? filter.searchFilter.gender_type.join() : ""}
        &status_type=${filter.searchFilter.status_type.length > 0 ? filter.searchFilter.status_type.join() : ""}
        &source_type=${filter.searchFilter.source_type.length > 0 ? filter.searchFilter.source_type.join() : ""}
        &competitor_type=${filter.searchFilter.competitor_type.length > 0 ? filter.searchFilter.competitor_type.join() : ""}
        &category_type=${filter.searchFilter.category_type.length > 0 ? filter.searchFilter.category_type.join() : ""}
        &subcategory_type=${filter.searchFilter.subcategory_type.length > 0 ? filter.searchFilter.subcategory_type.join() : ""}
        &transaction_type=${filter.searchFilter.transaction_type.length > 0 ? filter.searchFilter.transaction_type.join() : ""}
        &prefecture_type=${filter.searchFilter.prefecture_type.length > 0 ? filter.searchFilter.prefecture_type.join() : ""}
        &municipality_type=${filter.searchFilter.municipality_type.length > 0 ? filter.searchFilter.municipality_type.join() : ""}
        &area_type=${filter.searchFilter.area_type.length > 0 ? filter.searchFilter.area_type.join() : ""}
        &stages_type=${filter.searchFilter.stages_type.length > 0 ? filter.searchFilter.stages_type.join() : ""}
        &sms_type=${filter.searchFilter.sms_type.length > 0 ? filter.searchFilter.sms_type.join() : ""}
        &createdUser_type=${filter.searchFilter.createdUser_type.length > 0 ? filter.searchFilter.createdUser_type.join() : ""}
        &assignUser_type=${filter.searchFilter.assignUser_type.length > 0 ? filter.searchFilter.assignUser_type.join() : ""}
        &fdsUser_type=${filter.searchFilter.fdsUser_type.length > 0 ? filter.searchFilter.fdsUser_type.join() : ""}
        &ecc_type=${filter.searchFilter.ecc_type.length > 0 ? filter.searchFilter.ecc_type.join() : ""}
       
        &fromDateCreate_type=${filter.searchFilter.fromDateCreate_type}
        &toDateCreate_type=${filter.searchFilter.toDateCreate_type}
        &fromDateIncome_type=${filter.searchFilter.fromDateIncome_type}
        &toDateIncome_type=${filter.searchFilter.toDateIncome_type}
        &fromPrice_type=${filter.searchFilter.fromPrice_type}
        &toPrice_type=${filter.searchFilter.toPrice_type}
        &fromSq_type=${filter.searchFilter.fromSq_type}
        &toSq_type=${filter.searchFilter.toSq_type}
        `, { headers });

        let arrayData = {
            data: [],
            totalItemCount: 0
        };

        if(res.data.data.length > 0){
            let newItems = {...items};
  
            if(clear){
              arrayData = {
                data: res.data.data,
                totalItemCount: res.data.totalItemCount
              }
            }else{
              arrayData = {
                data: [...newItems.data, ...res.data.data],
                totalItemCount: res.data.totalItemCount
              }
            }
            dispatch(propertyBucketPropertiesSlice.actions.setStopFetch(false))
        }else{
            if(clear){}else{
                let newItems = {...items};

                arrayData = {
                    data: newItems.data,
                    totalItemCount: newItems.totalItemCount
                }
            }
            dispatch(propertyBucketPropertiesSlice.actions.setStopFetch(true))
        }

        return arrayData;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeletePropertyBucket = createAsyncThunk('propertyBucketProperties/startDeletePropertyBucket', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PROPERTY_BUCKET}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startOrderPropertyBucket = createAsyncThunk('propertyBucketProperties/startOrderPropertyBucket', async ({ locale, token, data }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_BUCKET}/order`, { buckets: data }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteFile = createAsyncThunk('propertyBucketProperties/startDeleteFile', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PROPERTY_BUCKET}/destroyFile/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDuplicatePropertyBucket = createAsyncThunk('propertyBucketProperties/startDuplicatePropertyBucket', async ({ locale, token, id, index, items }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_BUCKET}/duplicate/${id}`, { headers });

        const newItems = [...items.data];

        const newItem = { ...newItems[index] };

        newItem['id'] = res.data.id;
        newItem['sort'] = 0;
        newItem['comments'] = 0;
        newItem['user_image'] = res.data.user_image;
        newItem['user_create'] = res.data.user_create;
        newItem['user_name'] = res.data.user_name;
        newItem['income_date'] = res.data.created_at;
        newItem['created_at'] = res.data.created_at;

        newItems.unshift(newItem);

        const newArray = {
          data: newItems,
          totalItemCount: items.totalItemCount + 1
        }

        dispatch(propertyBucketPropertiesSlice.actions.fetchPropertyBucketsSuccess(newArray))

        return res.data.status;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


//Heading Functions
const setSelectedOrderOption = createAsyncThunk('propertyBucketProperties/setSelectedOrderOption', ( args ) => {
    return args;
});

const setSearch = createAsyncThunk('propertyBucketProperties/setSearch', ( args ) => {
    return args;
});

const initialState = {
    items: {
        data: [],
        totalItemCount: 0
    },
    selectedOrderOption: "",
    search: "",
    searchValue: "",
    searchFilterType: 1,
    fields: [],
    searchFilter: [
        { translate: "general.status", id: "status_type", dataKey: "status_types", search: "", type: 1 },
        { translate: "general.gender", id: "gender_type", dataKey: "gender_types", search: "", type: 1 },
        { translate: "general.source", id: "source_type", dataKey: "source_types", search: "", type: 1 },
        { translate: "menu.competitor-list", id: "competitor_type", dataKey: "competitor_types", search: "", type: 1 },
        { translate: "property.property-category", id: "category_type", dataKey: "category_types", search: "", type: 1 },
        { translate: "property.property-subcategory", id: "subcategory_type", dataKey: "subcategory_types", search: "", type: 1 },
        { translate: "property.luxury-property", id: "lux_prop_type", dataKey: "lux_prop_types", search: "", type: 1 },
        { translate: "property.property-transaction-types", id: "transaction_type", dataKey: "transaction_types", search: "", type: 1 },
        { translate: "general.prefectures", id: "prefecture_type", dataKey: "prefecture_types", search: "", type: 1 },
        { translate: "general.municipalities", id: "municipality_type", dataKey: "municipality_types", search: "", type: 1 },
        { translate: "general.areas", id: "area_type", dataKey: "area_types", search: "", type: 1 },
        { translate: "menu.stage-list", id: "stages_type", dataKey: "stages_types", search: "", type: 1 },
        { translate: "general.sms_mail", id: "sms_type", dataKey: "sms_types", search: "", type: 1 },
        { translate: "menu.create-user", id: "createdUser_type", dataKey: "createdUser_types", search: "", type: 1 },
        { translate: "general.assignee", id: "assignUser_type", dataKey: "assignUser_types", search: "", type: 1 },
        { translate: "general.fds", id: "fdsUser_type", dataKey: "fdsUser_types", search: "", type: 1 },
        { translate: "general.ecc", id: "ecc_type", dataKey: "ecc_types", search: "", type: 1 },
        
        { translate: "general.create-date", id: "create_date_type", dataKey: "", search: "", type: 1 },
        { translate: "general.income-date", id: "income_date_type", dataKey: "", search: "", type: 1 },
        { translate: "general.price", id: "price_type", dataKey: "", search: "", type: 1 },
        { translate: "property.property-sq-meters", id: "sq_type", dataKey: "", search: "", type: 1 },
    ],
    searchFilterValues: {
        status_type: [],
        gender_type: [],
        source_type: [],
        competitor_type: [],
        category_type: [],
        subcategory_type: [],
        lux_prop_type: [],
        transaction_type: [],
        prefecture_type: [],
        municipality_type: [],
        area_type: [],
        stages_type: [],
        sms_type: [],
        createdUser_type: [],
        assignUser_type: [],
        fdsUser_type: [],
        ecc_type: [],

        fromDateCreate_type: "",
        toDateCreate_type: "",
        fromDateIncome_type: "",
        toDateIncome_type: "",
        fromPrice_type: "",
        toPrice_type: "",
        fromSq_type: "",
        toSq_type: "",
    },
    createItem: '',
    updateItem: '',
    deleteItem: '',
    deleteFile: '',
    duplicateItem: '',
    orderItem: '',
    stopFetch: false,
    loadingBtn: false,
    loading: true,
    loadingSearch: false,
    error: ''
}

export const propertyBucketPropertiesSlice = createSlice({
    name: 'propertyBucketProperties',
    initialState,
    reducers: {
        fetchPropertyBucketsSuccess(state, { payload }) {
            return { ...state, loading: false, loadingSearch: false, items: payload, error: '' };
        },
        clearPropertyBucket(state) {
            return { ...state, stopFetch: false, loadingBtn: false, loading: false, loadingSearch: false, itemData: initialState.itemData, error: '' };
        },
        clearAlertPropertyBucket(state) {
            return { ...state, createItem: '', updateItem: '', deleteItem: '', duplicateItem: '', orderItem: '', deleteFile: '', error: '' };
        },

        setFilter(state, { payload }) {
            return { ...state, searchFilterValues: payload };
        },
        setFilterType(state, { payload }) {
            return { ...state, searchFilterType: payload };
        },
        setFilterFieldSearch(state, { payload }) {
            return { ...state, fields: payload.dataValues, searchFilter: payload.values };
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        actionFetchPropertyBuckets(state, { payload }) {
            return { ...state, loading: payload ? true : false, loadingSearch: true, error: '' };
        },
        setStopFetch(state, { payload }) {
            return { ...state, stopFetch: payload }
        },
    },
    extraReducers: {
      [startFetchSearchFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        return { ...state, fields: payload, error: '' };
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, fields: initialState.fields, error: payload };
      },

      [startCreatePropertyBucket.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreatePropertyBucket.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createItem: payload, error: '' };
      },
      [startCreatePropertyBucket.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createItem: '', error: payload };
      },

      [startUpdatePropertyBucket.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdatePropertyBucket.fulfilled]: (state, { payload }) => {
        return { ...state, updateItem: payload, error: '' };
      },
      [startUpdatePropertyBucket.rejected]: (state, { payload }) => {
        return { ...state, updateItem: '', error: payload };
      },

      [startFetchPropertyBuckets.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, items: payload, error: '' };
      },
      [startFetchPropertyBuckets.rejected]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, items: initialState.items, error: payload };
      },

      [startDeletePropertyBucket.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteItem: payload, error: '' };
      },
      [startDeletePropertyBucket.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteItem: '', error: payload };
      },

      [startDuplicatePropertyBucket.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, duplicateItem: payload, error: '' };
      },
      [startDuplicatePropertyBucket.rejected]: (state, { payload }) => {
        return { ...state, loading: false, duplicateItem: '', error: payload };
      },

      [startOrderPropertyBucket.fulfilled]: (state, { payload }) => {
        return { ...state, orderItem: payload, error: '' };
      },
      [startOrderPropertyBucket.rejected]: (state, { payload }) => {
        return { ...state, orderItem: '', error: payload };
      },

      [startDeleteFile.fulfilled]: (state, { payload }) => {
        return { ...state, deleteFile: payload, error: '' };
      },
      [startDeleteFile.rejected]: (state, { payload }) => {
        return { ...state, deleteFile: '', error: payload };
      },

      //Heading Functions
      [setSelectedOrderOption.fulfilled]: (state, { payload }) => {
        return { ...state, selectedOrderOption: payload, currentPage: 1 };
      },
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      }
    },
});


export const propertyBucketPropertiesActions = {
    ...propertyBucketPropertiesSlice.actions,
    startFetchSearchFields,
    startCreatePropertyBucket,
    startUpdatePropertyBucket,
    startFetchPropertyBuckets,
    startDeletePropertyBucket,
    startDuplicatePropertyBucket,
    startOrderPropertyBucket,
    startDeleteFile,
    
    setSelectedOrderOption,
    setSearch
}
export default propertyBucketPropertiesSlice.reducer